import { Heading } from '@chakra-ui/react';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

const headers = {
  doctors: 'Médicos',
  search: 'Consulta',
  contract: 'Contrato',
  elaborate: 'Elaboração',
  reviewer: 'Aprovadores',
  'business-partner': 'Business Partner',
  'skill-map': 'Fichas de Competências Clínicas',
};

export default function Header() {
  const path = usePathname();

  const headerTitle = useMemo(() => headers[path?.split('/')[1]], [path]);

  const headerItems = useMemo(() => {
    if (path) {
      return path
        .split('/')
        .filter((path) => path)
        .slice(1);
    }

    return [];
  }, [path]);

  return (
    <Heading as={'h2'} fontSize={'xl'} fontWeight={600} marginBottom={2}>
      {headerTitle}
      {headerItems.map((item) => headers[item] && ` - ${headers[item]}`)}
    </Heading>
  );
}
